/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

    	  if ($('.scroll_down_btn').length) {
    		  $( window ).scroll(function () {
    			  $( ".scroll_down_btn" ).fadeOut();
    			  
    		  });
    		  $('.scroll_down_btn').click(function () {
  	      		var goto_target = $(this).data('goto');
  	      		$(this).fadeOut();
  	      		goToByScroll(goto_target);
  	      	});
    	  }


        /* products slider in homepage*/
        $('.home .products-slider').slick({
          slidesToShow: 5,
          slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '90px',
                        slidesToShow: 1
                    }
                }
            ]
        });

          /* products slider in homepage*/
          $('.single .products-slider').slick({
              slidesToShow: 5,
              slidesToScroll: 1,
              responsive: [
                  {
                      breakpoint: 480,
                      settings: {
                          arrows: false,
                          centerMode: true,
                          centerPadding: '70px',
                          slidesToShow: 1
                      }
                  }
              ]
          });




        /* fade in & out content on banner in homepage */
          
        $('.main_header').waypoint(function(direction) {
        	  if( direction === 'down') {
        		  $('.top_panel').removeClass("fadeIn").addClass("fadeOut");
              }
              else if( direction === 'up'){
            	  $('.top_panel').removeClass("fadeOut").addClass("fadeIn");
              }
        },
        {
              offset:250
        });
        
        $('.main-menu').waypoint(function(direction) {
      	  if( direction === 'down') {
      		  $('.scroll_down_btn').removeClass("fadeIn").addClass("fadeOut");
            }
            else if( direction === 'up'){
          	  $('.scroll_down_btn').removeClass("fadeOut").addClass("fadeIn");
            }
      },
      {
            offset: 100
      });




        $('.home .main-menu li').removeClass('current_page_item');
        $('a[href*="#"]:not([href="#"])').click(function() {
          //if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            var navbar_height = $('.main-menu').height();
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('.main-menu li').removeClass('current_page_item');
                $(this).parent().addClass('current_page_item');
              $('html, body').animate({
                  scrollTop: target.offset().top-navbar_height
              }, 1000);
              return false;
            }
          //}
        });

          setTimeout(function(){
              if(window.location.hash) {
                  var hash = window.location.hash; //Puts hash in variable, and removes the # character
                  //console.log(hash);
                  var target = $(hash);
                  var navbar_height = $('.main-menu').height();
                  if (target.length) {
                      //console.log(target.length);
                      $('html,body').animate({
                          scrollTop: target.offset().top-navbar_height
                      }, 1000);
                      var url = window.location.href;
                      $('.main-menu a').filter(function() {
                          return this.href === url;
                      }).parent().addClass('current_page_item');

                  }
                  else{
                      //console.log(target.length);
                  }
              } else {
                  // No hash found
                  //console.log('no hash');
                  //console.log(window.location.pathname);
                  var pathname = window.location.pathname;
              }
          }, 100);


          $('.home .article-item').waypoint(function(direction) {

              var previousWaypoint = this.previous();
              var sectionId = '';

              $('.main-menu li').removeClass('current_page_item');

              if( direction === 'down') {
                  articleItemId = $(this.element).find('span').attr('id');
                  //console.log('down articleItemId: ',articleItemId);
                  $('.main-menu li.'+articleItemId).addClass('current_page_item');
              }
              else if( direction === 'up'){
                  if(previousWaypoint){
                      articleItemId = $(previousWaypoint.element).attr('id');
                     //console.log('up sectionId: ',articleItemId);
                      $('.main-menu li.'+articleItemId).addClass('current_page_item');
                  }

              }
          }, {
              offset: '10%'
          });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
    	  // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

    function fixHeight(){
        var banner_height = $('.home .main-banner .banner').height();
        $('.inner-wrapper').css({'margin-top':banner_height + 'px'});
        $('.main_header').css({'top':banner_height + 'px'});
    }

    $(window).load(function(){
        /* If document contains homepage banner, fix the banner height. */
    	if (true == $('.home .main-banner .banner').length) fixHeight();

        /* header fixed */
        $('.main-menu').waypoint(function(direction) {
                if( direction === 'down') {
                    $('.main_header').addClass("fixed");
                }
                else if( direction === 'up'){
                    $('.main_header').removeClass("fixed");
                }
            },
            {
                offset:0
            });
    });

    var resizeTimer;

    $(window).on('resize', function(e) {

        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {

            fixHeight();

        }, 250);

    });
})(jQuery); // Fully reference jQuery after this point.

function goToByScroll(target){
	if (!$(target).length) return;
	var scrolltop_position = ('top' == target)?0:$(target).offset().top;
	scrolltop_position += -100;
	$('html,body').animate(
		{scrollTop: scrolltop_position},{
			duration: 1000,
			easing: 'swing'
		}
	);
}
